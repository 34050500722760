<template>
    <b-card :header="$t('navigations.partRequests')" header-tag="strong" class="part-requests-dashboard">
        <part-requests-table :widget="true"/>
    </b-card>
</template>

<script>
import PartRequestsTable from '@/views/Admin/Forms/PartRequests/list.vue'

export default {
    components: {
        PartRequestsTable
    }
};
</script>
