<template>
    <b-card :header="$t('navigations.timeTracking')" header-tag="strong" class="tracking-dashboard">
        <tracking-table :widget="true"/>
    </b-card>
</template>

<script>
import TrackingTable from '@/views/Admin/TimeTracking/TimeTracker/list.vue'

export default {
    components: {
        TrackingTable
    }
};
</script>
