<template>
    <div class="dashboard">
        <title-bar
            :title="$t('navigations.dashboard')"
        />
        <b-card
            overlay
            img-src="/aei_dashboard_image.png"
            img-alt="Aviation is a family where we all need each other. Working together!"
        />
        <b-row>
            <b-col lg="6">
                <banners v-if="userData.role !== 'ROLE_CUSTOMER'" />
                <read-and-understand v-if="userData.role !== 'ROLE_CUSTOMER'" />
                <email v-if="userData.role !== 'ROLE_CUSTOMER'" />
                <ocr v-if="displayOcr()" />
            </b-col>
            <b-col lg="6">
                <links/>
                <holiday v-if="displayHoliday()"/>
                <receipts v-if="displayReceipts()"/>
                <time-tracking v-if="displayTimeTracking()" />
                <part-requests v-if="displayPartRequests()" />
                <tool-requests v-if="displayToolRequests()" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ReadAndUnderstand from "./components/readAndUnderstand.vue";
import Banners from "./components/Banners.vue";
import Links from "./components/Links.vue";
import Holiday from "./components/Holiday.vue";
import Email from "@/views/Admin/Dashboard/components/Email";
import Receipts from "@/views/Admin/Dashboard/components/Receipts";
import TimeTracking from "@/views/Admin/Dashboard/components/TimeTracking";
import PartRequests from "@/views/Admin/Dashboard/components/PartRequests";
import ToolRequests from "@/views/Admin/Dashboard/components/ToolRequests";
import Ocr from "@/views/Admin/Dashboard/components/Ocr";
import {getUserData} from '@/auth/utils'

export default {
    components: {
        Email,
        TitleBar,
        ReadAndUnderstand,
        Banners,
        Links,
        Holiday,
        Receipts,
        TimeTracking,
        PartRequests,
        ToolRequests,
        Ocr
    },
    data() {
        return {
            userData: getUserData()
        }
    },
    methods: {
        displayHoliday() {
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_CTO) ||
               this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_FSD) ||
               this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_PPC) ||
               this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_SALES) ||
               this.userData.role === "ROLE_CUSTOMER") {
                return false
            }
            return true
        },
        displayReceipts() {
            return this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)
        },
        displayTimeTracking() {
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER) ||
            this.userData.role === "ROLE_CUSTOMER") {
                return false
            }
            return true
        },
        displayPartRequests() {
            return (this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_SALES) || this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_PPC))
        },
        displayToolRequests() {
            return this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_CTO)
        },
        displayOcr() {
            return this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_CTO)
        }
    }
};
</script>

<style>
</style>
